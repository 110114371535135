import React from 'react'
import { graphql } from 'gatsby'
import { Box, Flex } from '@rebass/emotion'

import { CurveBox, HeroHeadline, Paragraph } from '../components/atoms'
import { Layout } from '../components/layouts/Layout'
import {
  ConstrainedWidthContainer,
  ProductSectionItem,
  SectionWithLink
} from '../components/molecules'
import { GetInTouch, SEO } from '../components/organisms'
import HeroSVG from '../images/globalscale_hero_small.svg'
import GlobalScaleMapImage from '../images/globalscale_body.svg'
import { Mq } from '../styles/settings/theme'
import styled from '../styles/styled'

export const SvgBox = styled(Box)`
  box-sizing: border-box;
  margin-left: 293px;
  margin-top: 65px;
`
const HeroImageContainer = styled(Box)`
  height: auto;
  order: -1;

  ${Mq.sm} {
    min-height: 30rem;
    order: 0;
  }
`

const ColoredBackgroundContainer = styled(Box)`
  background-color: ${props => props.theme.colors.lightBlue};
  position: relative;
`
const CurveBoxBottom = styled(CurveBox)`
  height: 50px;
  bottom: 0;
`

const CurveBoxTop = styled(CurveBox)`
  height: 50px;
  transform: rotate(180deg);
  top: 0;
`

const GlobalScalePage = ({ data }) => {
  const globalscaleItemsData = [
    {
      icon: null,
      iconAlt: null,
      headline: data.contentstackGlobalScalePage.global_scale_item_1_title,
      description:
        data.contentstackGlobalScalePage.global_scale_item_1_description,
    },
    {
      icon: null,
      iconAlt: null,
      headline: data.contentstackGlobalScalePage.global_scale_item_2_title,
      description:
        data.contentstackGlobalScalePage.global_scale_item_2_description,
    },
    {
      icon: null,
      iconAlt: null,
      headline: data.contentstackGlobalScalePage.global_scale_item_3_title,
      description:
        data.contentstackGlobalScalePage.global_scale_item_3_description,
    },
    {
      icon: null,
      iconAlt: null,
      headline: data.contentstackGlobalScalePage.global_scale_item_4_title,
      description:
        data.contentstackGlobalScalePage.global_scale_item_4_description,
    },
  ]

  const globalscalePaymentsItemsData = [
    {
      icon: null,
      iconAlt: null,
      headline: data.contentstackGlobalScalePage.global_scale_item_5_title,
      description: data.contentstackGlobalScalePage.global_scale_item_5_description,
    },
    {
      icon: null,
      iconAlt: null,
      headline: data.contentstackGlobalScalePage.global_scale_item_6_title,
      description: data.contentstackGlobalScalePage.global_scale_item_6_description,
    },
  ]

  return (
    <Layout>
      <SEO
        title={data.contentstackGlobalScalePage.title}
        description={data.contentstackGlobalScalePage.seo_description}
      />

      <ConstrainedWidthContainer>
        <Flex flexWrap="wrap" 
              justifyContent="space-between" 
              alignItems="center" 
              pb={[5]} mb={[3, 5]}>
          <Box width={[1, 6 / 12]}>
            <Box mb={[4]}>
              <HeroHeadline>
                {data.contentstackGlobalScalePage.hero_headline}
              </HeroHeadline>
            </Box>

            <Box width={[1, 1, 1, 10 / 12]}>
              <Paragraph>
                {data.contentstackGlobalScalePage.hero_description}
              </Paragraph>
            </Box>
          </Box>

          <HeroImageContainer width={[1, 6 / 12]}>
            <img src={HeroSVG} alt="Global Scale Pay Local"/>
          </HeroImageContainer>
        </Flex>
      </ConstrainedWidthContainer>

      <ConstrainedWidthContainer>
      <Flex
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems="center"
          >
          <Box px={[0, 0]} width={[1, 6 / 12]} mb={[5, 5]}>
                <ProductSectionItem
                  key={data.contentstackGlobalScalePage.global_scale_item_5_title}
                  headline={data.contentstackGlobalScalePage.global_scale_item_5_title}
                  description={data.contentstackGlobalScalePage.global_scale_item_5_description}
                  icon={{ url: null, altText: null }}
                  headlineColor="green"
                />
          </Box>
          <Box px={[0, 4]} width={[1, 6 / 12]} mb={[5, 5]}>
                <ProductSectionItem
                  key={data.contentstackGlobalScalePage.global_scale_item_6_title}
                  headline={data.contentstackGlobalScalePage.global_scale_item_6_title}
                  description={data.contentstackGlobalScalePage.global_scale_item_6_description}
                  icon={{ url: null, altText: null }}
                  headlineColor="green"
                />
          </Box>
        </Flex>
      </ConstrainedWidthContainer>

      <ColoredBackgroundContainer py={[6]}>
        <CurveBoxTop />
        <ConstrainedWidthContainer>
          <Flex
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box width={[1, 1, 6 / 12]}>
              {globalscaleItemsData.map(item => (
                <Box key={item.headline} mb={[3]}>
                  <ProductSectionItem
                    headline={item.headline}
                    description={item.description}
                    icon={{ url: item.icon, altText: item.iconAlt }}
                    headlineColor="black"
                  />
                </Box>
              ))}
            </Box>
            <Box width={[1, 6 / 12]} mb={[0, 5]}>
              <img src={GlobalScaleMapImage} alt="Accept payments worldwide"/>
            </Box>
          </Flex>
          <SectionWithLink
            text={
              data.contentstackGlobalScalePage.marketplaces_cta_description
            }
            linkUrl={
              data.contentstackGlobalScalePage.international_payment_methods_sheet.url
            }
            linkTitle={data.contentstackGlobalScalePage.international_payment_methods_sheet.title}
            description={data.contentstackGlobalScalePage.international_payment_methods_sheet.description}
            type="button"
          />
        </ConstrainedWidthContainer>
        <CurveBoxBottom />
      </ColoredBackgroundContainer>

      <GetInTouch />
    </Layout>
  )
}

export default GlobalScalePage

export const query = graphql`
  query {
    contentstackGlobalScalePage {
      hero_description
      hero_headline
      title
      seo_description
      marketplaces_cta_description
      global_scale_item_1_description
      global_scale_item_1_title
      global_scale_item_2_description
      global_scale_item_2_title
      global_scale_item_3_description
      global_scale_item_3_title
      global_scale_item_4_description
      global_scale_item_4_title
      global_scale_item_5_description
      global_scale_item_5_title
      global_scale_item_6_description
      global_scale_item_6_title
      international_payment_methods_sheet{
        title
        url
        filename
        content_type
        description
      }
    }
  }
`
